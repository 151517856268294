import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritvisualtourbuilder.scss";
import kofferLabel from '../assets/images/labels/visualtourbuilder-label.png';
import { Link } from "gatsby";

export default () => {
  return (
    <Layout>
      <SEO title="Travelspirit Visual Tour Builder" urlParams="travelspiritvisualtourbuilder" />
      <div>
        <div className="tour-travel-page-full back-office-section-first">
          <div className="tour-travel-page-container">
            <div className="tour-back-office-content">
              <h6>Interactieve offertes</h6>
              <div className="tour-back-office-wrap">
                <h1>VISUAL TOUR<br />BUILDER</h1>
                <img alt="backoffice-koffer label" src={kofferLabel} />
              </div>
              <p>Met de Visual Tour Builder (VTB) maak je snel <strong>maatwerkoffertes</strong> in je eigen stijl, zowel op mobiel als desktop. Wil je de offerte als PDF? Geen probleem! Wilt de klant wat wijzigen? Weekje later weg of toch andere accommodaties? <strong>Zo gepiept,</strong> de VTB zit vol slimmigheidjes en doet bijna al het werk vanzelf.</p>
              <ul className="tour-travel-page-container-ul">
                <li>Online offertes op maat</li>
                <li>Fotobeheer (MediaSpirit)</li>
                <li>Calculeren</li>
                <li>Pax Management</li>
                <li>Drag & Drop offertes maken</li>
                <li>Prijsberekeningen</li>
                <li>Elementen</li>
                <li>Beddenbanken</li>
                <li className="fullwidth">Bouwstenen</li>
                <li className="fullwidth">Voorbeeldreizen</li>
              </ul>
              <p className="tour-travel-btm-text">Heb je geen backoffice of CRM? <br />Bekijk dan onze Visual Tour Builder als losse tool <span>&gt;&gt;</span></p>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full back-office-section-two" id="customized-online-quotes">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left">
                <h3>“Kies je voor de interactieve website, dan heb je een echte‘eyecatcher’.”</h3>
              </div>
              <div className="tour-all-travel-common-box-right">
                <h3>Online offertes op maat</h3>
                <p>Met de Visual Tour Builder creëer je op maat gemaakte online offertes, beschikbaar als een interactieve website of een PDF.</p>
                <p>Kies je voor de interactieve website, dan heb je een echte ‘eyecatcher’. Deze is <strong>volledig</strong> aan te passen aan je <strong>huisstijl</strong>, met opties zoals fotosliders, video's, kaarten en call-to-action buttons. Je kunt de website <strong>live bewerken</strong> terwijl je de reis samenstelt, waardoor wijzigingen real-time zichtbaar zijn tot je hem naar de klant stuurt. De offerte wordt verzonden via een URL-link, zodat de klant het reisvoorstel kan bekijken als een <strong>mini-website.</strong></p>
                <p>We bieden ook een PDF-offerte in je huisstijl, die live bewerkt en opgeslagen wordt in onze BackOffice, zodat je hem eenvoudig kunt e-mailen.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-crm-office-section" id="calculate">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left">
                <h3>
                  “Zo berekend je altijd precies de juiste prijs door aan je
                  klant.”
                </h3>
              </div>
              <div className="tour-all-travel-common-box-right">
                <h3>Calculeren</h3>
                <p>De Visual Tour Builder kan met één <strong>druk op de knop</strong> een volledige maatwerk offerte calculeren. Hij houd hierbij rekening met seizoenen, kamertypes, kamerindeling, kinderen, baby's en nog veel meer.</p>
                <p>Zo bereken je altijd precies de <strong>juiste prijs</strong> door aan je klant en kan je jouw marge goed bewaken.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-telephonie-section" id="drag-drop">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left">
                <div></div>
              </div>
              <div className="tour-all-travel-common-box-right">
                <h3>Drag & Drop offertes maken</h3>
                <p>De Visual Tour Builder werkt met ‘Drag & Drop’, waardoor je gemakkelijk <strong>content</strong> kunt toevoegen, zoals:</p>
                <p>Verrijk je offerte met content uit <strong>interne</strong> en <strong>externe bronnen.</strong> Met de eenvoudige “Drag and Drop”-functie voeg je snel onder andere het volgende toe: 
                </p>
                <ul className="tour-list-ul">
                  <li>Elementen (zoals hotels, excursies, autoverhuur, etc.)</li>
                  <li>Bouwstenen (bijvoorbeeld 10 dagen Bali)</li>
                  <li>Teksten (tips, stadsinformatie, etc.)</li>
                  <li>Afbeeldingen</li>
                  <li>Prijzen</li>
                  <li>Reizigers</li>
                </ul>
                <p>Met deze gebruiksvriendelijke functie bespaar je tijd. Daarnaast kun je je eigen <strong>producten</strong> aanmaken en <strong>opslaan</strong> in de <strong>bibliotheek</strong> voor eenvoudig hergebruik en beheer.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-email-section" id="elementen">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left"></div>
              <div className="tour-all-travel-common-box-right">
                <h3>Elementen</h3>
                <p>De Visual Tour Builder is verbonden met het TravelSpirit BackOffice systeem. Alle daar aanwezige elementen kunnen worden gebruikt om een reis mee <strong>samen te stellen.</strong></p>
                <p>Denk aan hotels, exclusief, transfers en autohuur. Met al deze elementen komen de <strong>teksten</strong> en <strong>foto's</strong> automatisch mee, waarna ze nog gewijzigd of uitgebreid kunnen worden.</p>
                <p>De VTB zal de prijzen, wanneer die in de BackOffice zijn geladen, gebruiken om de <strong>totale reissom,</strong> en die <strong>per persoon</strong> mee te berekenen.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-workflow-section" id="book-online">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left"></div>
              <div className="tour-all-travel-common-box-right">
                <h3>Bouwstenen</h3>
                <p>Naast het werken met losse elementen, kun je ook gebruik maken van bouwstenen. Dit is een <strong>verzameling elementen,</strong> zoals een tiendaagse trip naar Bali met: transfer, 9 dagen autohuur, negen excursies en een boottocht naar de volgende bestemming.</p>
                <p>Bouwstenen zijn in het BackOffice systeem gemakkelijk te maken, of te hergebruiken uit <strong>bestaande Travelplans.</strong> Een bouwsteen kun je, nadat je die in de VTB hebt gesleept, nog aanpassen, aan bijv. een ander hotel of een dagje extra verblijf.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-producten-section" id="voorbeeldreizen">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left">
                <h3>“Door deze simpelweg in de Visual Tour Builder te slepen, komen alle bouwstenen en elementen beschikbaar.”</h3>
              </div>
              <div className="tour-all-travel-common-box-right">
                <h3>Voorbeeldreizen</h3>
                <p><strong>Een complete reis</strong> kan ook <strong>opgeslagen</strong> worden als <strong>template travelplan.</strong> Door deze simpelweg in de Visual Tour Builder te slepen, komen alle bouwstenen en elementen beschikbaar.</p>
                <p>De template kan daarna worden <strong>gefinetuned</strong> voor de nieuwe klant, waarbij je kunt denken aan: het wijzigen van de reis naar een andere datum, het hier en daar toevoegen van wat opties, of het veranderen van de volgorde van de reis.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-bestellingen-section">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left"></div>
              <div className="tour-all-travel-common-box-right">
                <h3>Fotobeheer (MediaSpirit)</h3>
                <p>Beelden zeggen meer dan duizend woorden! Verfraai de reisoffertes met mooie foto's. Deze zijn altijd <strong>op maat</strong>, wanneer je gebruikmaakt van MediaSpirit, of ze nu bestemd zijn voor de offerte als website, of voor de PDF.</p>
                <p>De foto's kun je terugvinden met de <strong>tags</strong> die je zelf aan de foto's hebt toegekend, alsmede met <strong>cognitieve tags,</strong> zoals 'sunset' of 'temple', die door het MediaSpirit systeem zijn toegevoegd.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-fotobeheer-section" id="pax-management">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left"></div>
              <div className="tour-all-travel-common-box-right">
                <h3>Pax Management</h3>
                <p>De Visual Tour Builder kan alle reiscombinaties aan, of iemand alleen reist, met gezin, of met de duikvereniging. In de <strong>PAX manager</strong> kun je <strong>party’s aanmaken</strong> met personen die een kamer willen delen.</p>
                <p>Bij het inslepen van elementen of bouwstenen, zal de VTB meerdere kamers toevoegen.</p>
                <p>Komt er later nog iemand bij, dan helpt de VTB bij het <strong>herindelen van de groep.</strong></p>
                <p>De prijs wordt <strong>per persoon</strong> berekend. Degene die bijv. een kamer voor zichzelf prefereert, betaalt ook wat meer.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-facturen-section">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left"></div>
              <div className="tour-all-travel-common-box-right">
                <h3>Prijsberekeningen</h3>
                <p>Met de Visual Tour Builder worden <strong>prijzen berekend</strong> aan de hand van de <strong>periode</strong> en het <strong>aantal personen,</strong> automatisch berekend, waarbij allerlei <strong>specials</strong> zijn ingecalculeerd.</p>
                <p>Van de incidentele elementen kun je de <strong>prijs rechtstreeks</strong> in de VTB invoeren. Dit vindt plaats in een soort Excel sheet die <strong>direct gekoppeld</strong> is aan het reisplan.</p>
                <p>De berekeningen werken bi-directioneel. Pas je het marge percentage aan, dan wordt de verkoopprijs aangepast en vice versa.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-intelligence-section">
          <div className="tour-travel-page-container">
            <div className="tour-all-travel-common-box-main">
              <div className="tour-all-travel-common-box-left">
                <div></div>
              </div>
              <div className="tour-all-travel-common-box-right">
                <h3>Beddenbanken</h3>
                <p>De Visual Tour Builder heeft verbinding met een <strong>60-tal beddenbanken</strong> (denk aan Hotelbeds, Expedia, AOT, etc.)</p>
                <p>Afhankelijk van de beddenbank waar je afspraken mee hebt gemaakt, kan de VTB de <strong>beste prijs</strong> zoeken.</p>
                <p>Er wordt ook gekeken naar de <strong>beschikbaarheid</strong> van het hotel voor de <strong>gewenste periode.</strong></p>
                <p>Foto's en teksten kunnen <strong>automatisch</strong> in de VTB worden geladen. Het systeem zorgt ervoor dat de teksten voor het overgrote deel in het Nederlands zullen worden aangeleverd. Mocht de klant akkoord gaan met de offerte, dan kan het BackOffice systeem de <strong>boeking doorsturen</strong> naar de <strong>juiste beddenbank.</strong></p>
              </div>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-kost-section">
          <div className="tour-travel-page-container">
            <div className="tour-kost-section-content">
              <h2>Wat kost de Visual Tour Builder?</h2>
              <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de Visual Tour Builder</p>
              <Link key='tour-builder-pricing' to={`/pricing`}>
                  Bekijk abonnementen <span>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="tour-travel-page-full tour-benieuwd-section">
          <div className="tour-travel-page-container">
            <div className="tour-benieuwd-section-content">
              <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
              <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
              <div className="tour-benieuwd-btn">
                <div className="tour-benieuwd-btn-item">
                    <Link key='tour-builder-demo' to={`/demo`} className="btn btn-primary tour-demo-implement">
                        Demo inplannen
                    </Link>
                    <span className="tour-benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                </div>
                <div className="tour-benieuwd-btn-item">
                    <Link key='tour-builder-verkennend' to={`/demo-afspraak`} className="btn btn-primary tour-verkennend-implement">
                        Verkennend gesprek
                    </Link>
                    <span className="tour-benieuwd-btn-item-text">Stel online al jouw vragen</span>
                </div>
              </div>
              {/* <div className="tour-benieuwd-btn">
                <button className="btn btn-primary tour-demo-implement">Demo inplannen</button>
                <button className="btn btn-primary tour-verkennend-implement">Verkennend gesprek</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
